import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import bookingimage from "../../../img/plus.png";
import "./Profile.css";
import menuimage from "../../../assets/img/close.png";
import menucross from "../../../assets/img/menu.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IP } from "../../../../Constant";
import {
    faArrowRightFromBracket,
    faBell,
    faBorderAll,
    faCalendarDays,
    faGift,
    faHeadset,
    faHeart,
    faIdBadge,
    faMoneyCheckDollar,
    faSpa,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [notificationSum, setNotificationSum] = useState(0);
    const [valuestatus, setZerovalue] = useState(false)
    const token = localStorage.getItem("token");
    const nav = useNavigate();
    let userId = localStorage.getItem("userid")

    const handleLogout = () => {
        localStorage.removeItem("token");
        nav("/");
    };

    const handleSidebarToggle = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };

    const handleBook = () => {
        nav("/select_location");
    };



    const handleNotificationClick = async (type) => {
        try {
            const response = await fetch(`${IP}/notifications/${userId}/status?type=${type}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token, // Include token here
                },
                body: JSON.stringify({ status: 0 }), // Set status to 0
            });

            if (!response.ok) {
                throw new Error('Failed to update status');
            }

            const result = await response.json();
            console.log(`${type.charAt(0).toUpperCase() + type.slice(1)} status updated:`, result);
            setZerovalue(true);

            // Navigate to 
            nav(type === 'notification' ? "/userProfile/notification" : "/userProfile/booking");
        } catch (error) {
            console.error(`Error updating ${type} status:`, error);
        }
    };


    const fetchNotificationStatusSum = async () => {
        try {
            const response = await fetch(`${IP}/notifications/user/${userId}/status/sum`, {
                headers: {
                    Authorization: token,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch notification status sum');
            }
            const result = await response.json();
            console.log("Notification value retrieved");
            setNotificationSum(result);
            console.log("Notification status sum:", result);
        } catch (error) {
            console.error("Error fetching notification status sum:", error);
        }
    };
    useEffect(() => {
        fetchNotificationStatusSum()
    }, [valuestatus])
    return (
        <div className="parent">
            <div className={`sidebar_tab ${isSidebarOpen && "mobile-view"}`}>
                <div>
                    <ul id="tabs_control">
                        <Link to="/userProfile">
                            <li id="tab_1" className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
                                <div className="item" onClick={() => {
                                    handleNotificationClick("booking");
                                    handleSidebarToggle();
                                }}>
                                    <FontAwesomeIcon icon={faBorderAll} style={{ marginRight: 10 }} />
                                    Overview
                                    <span className="notiAlert">
                                        {notificationSum?.bookingTotalStatus > 0 ? notificationSum?.bookingTotalStatus : 0}
                                    </span>
                                </div>
                            </li>
                        </Link>
                        <Link to="/userProfile/bookinghistory">
                            <li id="tab_2" className={activeTab === 2 ? "active" : ""} onClick={() => setActiveTab(2)}>
                                <div className="item" onClick={handleSidebarToggle}>
                                    <FontAwesomeIcon icon={faCalendarDays} style={{ marginRight: 10 }} />
                                    Prior Bookings
                                </div>
                            </li>
                        </Link>
                        <Link to="/userProfile/membership">
                            <li id="tab_4" className={activeTab === 4 ? "active" : ""} onClick={() => setActiveTab(4)}>
                                <div className="item" onClick={handleSidebarToggle}>
                                    <FontAwesomeIcon icon={faMoneyCheckDollar} style={{ marginRight: 10 }} />
                                    Membership
                                </div>
                            </li>
                        </Link>
                        <Link to="/userProfile/usergift">
                            <li id="tab_5" className={activeTab === 5 ? "active" : ""} onClick={() => setActiveTab(5)}>
                                <div className="item" onClick={handleSidebarToggle}>
                                    <FontAwesomeIcon icon={faGift} size={15} style={{ marginRight: 10 }} />
                                    Gift Card
                                </div>
                            </li>
                        </Link>
                        <Link to="/userProfile/profile">
                            <li id="tab_6" className={activeTab === 6 ? "active" : ""} onClick={() => setActiveTab(6)}>
                                <div className="item" onClick={handleSidebarToggle}>
                                    <FontAwesomeIcon icon={faIdBadge} style={{ marginRight: 10 }} />
                                    Profile
                                </div>
                            </li>
                        </Link>
                        <Link to="/userProfile/notification">
                            <li id="" className={activeTab === 8 ? "active" : ""} onClick={() => setActiveTab(8)}>
                                <div className="item" onClick={() => {
                                    handleNotificationClick("notification");
                                    handleSidebarToggle();
                                }}>
                                    <FontAwesomeIcon icon={faBell} style={{ marginRight: 10 }} />
                                    Notifications
                                    <span className="notiAlert">
                                        {notificationSum?.notificationTotalStatus > 0 ? notificationSum?.notificationTotalStatus : 0}
                                    </span>
                                </div>
                            </li>
                        </Link>

                        <Link to="/userProfile/favorites">
                            <li id="" className={activeTab === 9 ? "active" : ""} onClick={() => setActiveTab(9)}>
                                <div className="item" onClick={handleSidebarToggle}>
                                    <FontAwesomeIcon icon={faHeart} style={{ marginRight: 10 }} />
                                    Favorites
                                </div>
                            </li>
                        </Link>
                        <Link to="/userProfile/support">
                            <li id="" className={activeTab === 10 ? "active" : ""} onClick={() => setActiveTab(10)}>
                                <div className="item" onClick={handleSidebarToggle}>
                                    <FontAwesomeIcon icon={faHeadset} style={{ marginRight: 10 }} />
                                    Support
                                </div>
                            </li>
                        </Link>
                        <li id="tab_7" className={activeTab === 7 ? "active" : ""}>
                            <div className="item" onClick={handleLogout}>
                                <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ marginRight: 10 }} />
                                Logout
                            </div>
                        </li>
                        <div className="userbooking">
                            <li id="tab_7">
                                <div className="item" onClick={handleBook}>
                                    <FontAwesomeIcon icon={faSpa} style={{ marginRight: 10 }} />
                                    Book now
                                </div>
                            </li>
                        </div>
                    </ul>
                    <div id="navigationuser">
                        <div className="toggle_buttons" onClick={handleSidebarToggle}>
                            {isSidebarOpen ? (
                                <img src={menuimage} alt="Close" className="toggleimages" />
                            ) : (
                                <img src={menucross} alt="Menu" className="toggleimages" />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div id="imagebooking">
                <img
                    width={40}
                    height={40}
                    src={bookingimage}
                    alt=""
                    style={{
                        borderRadius: "100%",
                        display: "block",
                        boxShadow: "4px 4px 4px 0px rgb(6 77 109)",
                    }}
                    className="profileiconimage"
                    onClick={() => nav("/guest_login")}
                />
            </div>
        </div>
    );
};

export default Sidebar;
