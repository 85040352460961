import React from 'react'
import Container from 'react-bootstrap/Container'
import MultiStepForm from '../components/multistep/MultiStepForm'

const ApplicationForm = () => {
  return (
   <Container>
   <MultiStepForm/>
 </Container>
  )
}

export default ApplicationForm