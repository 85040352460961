import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../../Components/assets/img/logo_home_navbar.png";
import { IP } from "../../Constant";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDashboard,
  faCalendarAlt,
  faConciergeBell,
  faMoneyBillWave,
  faBell,
  faStar,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons"; // Import icons you will use

const SideBar = () => {
  const nav = useNavigate();
  const [review, setReview] = useState(0);
  const [user, setUser] = useState("");
  const [notification, setNotifications] = useState(0);
  const [valuestatus, setZerovalue] = useState(false)
  const application_status = localStorage.getItem("application_status");
  const token = localStorage.getItem("providertoken")
  let userId = localStorage.getItem("userid")
  const user_id = localStorage.getItem("provider_id");

  useEffect(() => {
    const token = localStorage.getItem("providertoken");

    try {
      fetch(`${IP}/provider/application-status`, {
        headers: {
          Authorization: token,
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          setUser(result.application_status);
          localStorage.setItem("application_status", result.application_status);
          console.log("application-status", result.application_status);
          if (result.application_status >= 3) {
            localStorage.setItem("approvaluser", "approval");
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, [nav]);


  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .get(`${IP}/get-allprovider-notifications/${user_id}`, config)
      .then((res) => {
        console.log("provider notification", res.data); // Log the response to check the structure
        if (Array.isArray(res.data)) {
          setNotifications(res?.data?.length);  // Only set the length if it's an array
        } else {
          console.log("Response is not an array");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    const fetchReviews = async () => {
      try {
        // Remove the extra } in the URL
        const resp = await fetch(`${IP}/bookings/get-all-review`, {
          headers: {
            Authorization: token,
          },
        });

        if (!resp.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await resp.json();

        // Check if result.reviews is an array before accessing length
        if (Array.isArray(result?.reviews)) {
          setReview(result.reviews.length);
        } else {
          console.error("Reviews data is not an array");
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        // Optional cleanup if needed
      }
    };

    fetchReviews();
  }, []); // Trigger useEffect on startDate or endDate change

  console.log("reviewreviewreviewreviewreviewreviewreviewreviewreviewreviewreviewreview", review)

  return (
    <>
      <div className="sidebar col-md-3 sticky">
        <div className="sidebar-title">
          <Link className="navbar-brand" to="/providers">
            <img
              src={image1}
              width="200"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Link>
        </div>
        <div className="sidebar-menu">
          {application_status >= "4" ? (
            <>
              <Link to="/providers">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faDashboard} className="menu-icon" />
                  Dashboard
                </div>
              </Link>

              <Link to="/providers/events">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" />
                  Appointments

                </div>
              </Link>

              <Link to="/providers/services">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faConciergeBell} className="menu-icon" />
                  Services
                </div>
              </Link>

              <Link to="/providers/earnings">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faMoneyBillWave} className="menu-icon" />
                  Earnings
                </div>
              </Link>

              <Link to="/providers/notification">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faBell} className="menu-icon" />
                  Notification
                  {
                    notification > 0 ? (
                      <span style={{
                        marginLeft: "15px",
                        backgroundColor: 'green', // Red background for visibility
                        color: '#000', // White text color
                        borderRadius: '100%', // Rounded corners
                        padding: '5px 5px', // Padding for better spacing
                        fontSize: '14px', // Font size
                        verticalAlign: 'middle' // Aligns it nicely with the text
                      }}>


                      </span>
                    ) : (
                      <span style={{
                        marginLeft: "15px",
                        backgroundColor: '#ffff', // Red background for visibility
                        color: '#000', // White text color
                        borderRadius: '100%', // Rounded corners
                        padding: '5px 5px', // Padding for better spacing
                        fontSize: '14px', // Font size
                        verticalAlign: 'middle' // Aligns it nicely with the text
                      }}>


                      </span>
                    )
                  }

                </div>
              </Link>

              <Link to="/providers/review">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faStar} className="menu-icon" />
                  Review

                  {
                    review > 0 ? (
                      <span style={{
                        marginLeft: "15px",
                        backgroundColor: 'green', // Red background for visibility
                        color: '#000', // White text color
                        borderRadius: '100%', // Rounded corners
                        padding: '5px 5px', // Padding for better spacing
                        fontSize: '14px', // Font size
                        verticalAlign: 'middle' // Aligns it nicely with the text
                      }}>


                      </span>
                    ) : (
                      <span style={{
                        marginLeft: "15px",
                        backgroundColor: '#ffff', // Red background for visibility
                        color: '#000', // White text color
                        borderRadius: '100%', // Rounded corners
                        padding: '5px 5px', // Padding for better spacing
                        fontSize: '14px', // Font size
                        verticalAlign: 'middle' // Aligns it nicely with the text
                      }}>


                      </span>
                    )
                  }



                </div>
              </Link>


              {application_status !== "4" && (
                <Link to="/providers/application-form">
                  <div className="menu-item">
                    <FontAwesomeIcon icon={faFileAlt} className="menu-icon" />
                    Application Form
                  </div>
                </Link>
              )}
            </>
          ) : (
            <>
              <Link to="/providers/waiting">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faDashboard} className="menu-icon" />
                  Dashboard
                </div>
              </Link>

              <Link to="/providers/waiting">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" />
                  My Events
                </div>
              </Link>

              <Link to="/providers/waiting">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faConciergeBell} className="menu-icon" />
                  Services
                </div>
              </Link>

              <Link to="/providers/waiting">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faMoneyBillWave} className="menu-icon" />
                  Earnings
                </div>
              </Link>

              <Link to="/providers/application-form">
                <div className="menu-item">
                  <FontAwesomeIcon icon={faFileAlt} className="menu-icon" />
                  Application Form
                </div>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;
