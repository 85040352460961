import React from 'react'
import Banner from './Banner'
import Form from './Form'

function Provider() {
  return (
    <>
    <Banner/>
    </>
  )
}

export default Provider