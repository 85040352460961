import React, { useState, useEffect, useMemo } from 'react';
import { IP } from "../../Constant";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import PreviewImage from '../../Components/Pages/Guest_login/GuesMenu/PreviewImage';

function EarningCard() {
    const [data, setData] = useState(1);
    const [count, setCount] = useState(0);

    const token = localStorage.getItem("providertoken");
    const [user, setUser] = useState([]);

    useEffect(() => {
        fetch(`${IP}/provider/getPayment`, {
            headers: {
                'Authorization': token
            }
        }).then(resp => resp.json())
            .then(result => {
                setUser(result?.payments);
                setCount(result?.payments?.length);
                console.log("earning", result?.payments);
            }).catch(err => {
                console.log(err);
            });
    }, [data]);

    const handlePageClick = (data) => {
        setData(data.selected + 1);
    };

    const memoizedUser = useMemo(() => {
        if (Array.isArray(user)) {
            return user.slice((data - 1) * 10, data * 10);
        } else {
            console.error("User is not an array");
            return [];
        }
    }, [user, data]);

    return (
        <>
            <div className="row">
                <div className="gutter">
                    <table className="table-responsive ultra_responsive">
                        <thead>
                            <tr>
                                <th>Payment Proof</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Payment ID</th>
                                <th>Date</th> {/* New Date column */}
                            </tr>
                        </thead>
                        {memoizedUser.map((cur, index) => (
                            <React.Fragment key={index}>
                                <tbody id="post_container">
                                    <tr className="wrapper" id={`tr_post_${index}`}>
                                        <td>
                                            <div className="avatar_wrap">
                                                <div className="inner">
                                                    <div
                                                        className="preview"
                                                        style={{
                                                            width: "50%",
                                                            backgroundSize: "cover",
                                                        }}
                                                    >
                                                        <PreviewImage
                                                            className="avatar"
                                                            attachments={cur.attachments}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="content">
                                                <span className="title">{cur.additionalInfo}</span>
                                                <span
                                                    className="title"
                                                    style={{ display: "block", fontSize: "12px" }}
                                                >
                                                    {cur.email}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="content">
                                                <span className="title">
                                                    {cur?.amount?.toFixed(2)}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="content">
                                                <span className="title">{cur?.paymentId}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="content">
                                                <span className="title">
                                                    {moment(cur.createAt).format("DD/MM/YYYY")}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </React.Fragment>
                        ))}
                    </table>
                </div>
                <div className="pagination">
                    <ReactPaginate
                        itemsPerPage={10}
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={"..."}
                        pageCount={Math.ceil(count / 10)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center py-3'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </div>
            </div>
        </>
    );
}

export default EarningCard;
